import { projectFirestore } from "../../../../../firebase/config";

const save_song_in_db = async(song) => {
    const doc = projectFirestore.collection('Applications').doc('zMmmIKFq2BOQEWwnZVVM')
    .collection('Songs').doc()
    await doc.set({
        ...song,
        uid:doc.id,
    })
}

const edit_song_in_db = async(song) => {
    await projectFirestore.collection('Applications').doc('zMmmIKFq2BOQEWwnZVVM')
    .collection('Songs').doc(song.uid).set(song,{merge:true})
}

const get_songs_from_db = async() => {
    const docs = await projectFirestore.collection('Applications').doc('zMmmIKFq2BOQEWwnZVVM')
    .collection('Songs').get()
    const res = docs.docs.map(doc=>doc.data())
    return res
}

const delete_song_from_db = async(song) => {
    await projectFirestore.collection('Applications').doc('zMmmIKFq2BOQEWwnZVVM')
    .collection('Songs').doc(song.uid).delete()
}


const get_promotion_song = async() => {
    const doc = await projectFirestore.collection('Applications').doc('zMmmIKFq2BOQEWwnZVVM')
    .collection('Promotions').doc('promotion_song').get()
    return doc.data()
}

const save_dispatch_song_in_db = async(song) => {
    console.log(song);
    await projectFirestore.collection('Applications').doc('zMmmIKFq2BOQEWwnZVVM')
    .collection('Promotions').doc('promotion_song').set(song)
}

const delete_promotion_song = async() => {
    const doc = await projectFirestore.collection('Applications').doc('zMmmIKFq2BOQEWwnZVVM')
    .collection('Promotions').doc('promotion_song').delete()
}



export{
    save_song_in_db,
    get_songs_from_db,
    delete_song_from_db,
    edit_song_in_db,
    save_dispatch_song_in_db,
    get_promotion_song,
    delete_promotion_song,
}