<template>
  <div v-loading="is_pending" class="radio-archive">
      <div class="radio-archive-header">
          <h2>רשימת שירים להשמעה</h2>
      </div>
      <div class="radio-archive-tools">
          <el-input style="width:30%;" v-model="search" placeholder="חיפוש" />
      </div>
      <div v-if="platform=='desktop' || platform=='tablet'" class="radio-archive-content">
          <table>
              <tr>
                  <th>שם השיר</th>
                  <th>כתובת URL</th>
                  <th>פעולות</th>
              </tr>
              <template v-for="song in slice_songs" :key="song.uid">
                  <tr>
                      <td>{{song.song_name}}</td>
                      <td>{{song.song_url}}</td>
                      <td>
                          <i @click="handle_delete_song(song)" class="material-icons delete-icon icon">delete</i>
                          <i @click="handle_edit_song(song)" class="material-icons edit-icon icon">edit</i>
                          <i @click="handle_copy_to_clipboard(song.song_url)" class="material-icons copy-icon icon">content_copy</i>
                      </td>
                  </tr>
              </template>  
          </table>
      </div>
      <div v-if="platform=='mobile'" class="radio-archive-content">
           <template v-for="song in slice_songs" :key="song.uid">
               <table style="margin-bottom:5px;">
                   <tr>
                       <th>שם השיר</th>
                       <td>{{song.song_name}}</td>
                   </tr>
                   <tr>
                       <th>כתובת URL</th>
                       <td>
                            {{song.song_url}}
                       </td>
                   </tr>
                   <tr>
                       <th>פעולות</th>
                       <td>
                          <i @click="handle_delete_song(song)" class="material-icons delete-icon icon">delete</i>
                          <i @click="handle_edit_song(song)" class="material-icons edit-icon icon">edit</i>
                          <i @click="handle_copy_to_clipboard(song.song_url)" class="material-icons copy-icon icon">content_copy</i>
                      </td>
                   </tr>
               </table>
           </template>
      </div>
      <div class="radio-archive-pagination">
          <el-pagination 
             v-model:currentPage="pagination.current_page"
             background 
            :page-size="pagination.number_per_page"
            layout="prev,pager,next"
            :total="filter_by_search.length"
        />
      </div>
      <CreateNewSong @close="show_edit_song=false" @finish_edit="handle_finish_edit" :song="selected_song" v-if="show_edit_song" />
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import {get_songs_from_db,delete_song_from_db} from './scripts/script'
import {alert_confirm,slide_pop_successs} from '../../../../Methods/Msgs'
import upload_image_storage from '../../../../Methods/use_general_storage'
import CreateNewSong from './CreateNewSong.vue'
import { computed } from '@vue/runtime-core'
import store from '../../../../store'
export default {
    components:{CreateNewSong},
    setup(){
        
        const {delete_image_by_url} = upload_image_storage()
        const platform = ref(computed(()=>{
            return store.state.platform
        }))
        const search = ref('')
        const songs = ref([])
        const is_pending = ref(false)
        const selected_song = ref(null)
        const show_edit_song = ref(false)

        const pagination = ref({
            current_page:1,
            number_per_page:15,
        })

        const slice_songs = ref(computed(()=>{
            return filter_by_search.value.slice((pagination.value.current_page-1)*pagination.value.number_per_page,
            ((pagination.value.current_page-1)*pagination.value.number_per_page)+pagination.value.number_per_page)
        }))

        const filter_by_search = ref(computed(()=>{
            if(search.value=='') return songs.value
            return songs.value.filter(song=>{
                if(song.song_name.includes(search.value)) return song
            })
        }))

        const handle_copy_to_clipboard = (url) =>{
            navigator.clipboard.writeText(url);
            slide_pop_successs('כתובת ה-URL הועתקה בהצלחה')
        }
        const handle_delete_song = (song) => {
            alert_confirm(`למחוק את השיר ${song.song_name}?`)
            .then(async res=>{
                if(res.isConfirmed){
                    is_pending.value = true
                    if(song.upload_type == 'file'){
                        await delete_image_by_url(song.song_url)
                    }
                    await delete_song_from_db(song)
                    const index = songs.value.findIndex(_song=>_song.song_name == song.song_name)
                    index!=-1 && songs.value.splice(index,1)
                    is_pending.value = false
                }
            })
        }

        const handle_edit_song = (song)=>{
            selected_song.value = JSON.parse(JSON.stringify(song))
            show_edit_song.value = true
        }

        const handle_finish_edit = (song)=>{
            const index = songs.value.findIndex(_song=>_song.uid == song.uid)
            if(index!=-1) {songs.value[index] = song}
            show_edit_song.value = false
        }

        const init = async() => {
            is_pending.value = true
            songs.value = await get_songs_from_db()
            is_pending.value = false
        }

        init()
        return{
            handle_delete_song,
            handle_edit_song,
            handle_finish_edit,
            handle_copy_to_clipboard,
            search,
            songs,
            pagination,
            slice_songs,
            filter_by_search,
            is_pending,
            selected_song,
            show_edit_song,
            platform,
        }
    }
}
</script>

<style scoped>
    .radio-archive{
        position: relative;
        width: 100%;
        height: 100%;
        color: #fff;
    }
    .radio-archive-header{
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .radio-archive-tools{
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        padding: 0 5px;
    }
    .radio-archive-content{
        width: 100%;
        height: calc(100% - 150px);
        overflow-y: auto;
        padding: 0 5px;
    }

    table {
        border-collapse: collapse;
        width: 100%;
    }
    table td, table th {
        border: 1px solid #ddd;
        padding: 2px;       
    }
    table tr:nth-child(even){background-color: #f2f2f2;}
    table tr:nth-child(odd){background-color: #e7e6e6;}
    table tr:hover {background-color: #ddd;}
    table td{
        text-align: center;
        color: var(--main);
        font-size: 18px;
        max-width: 200px;
        overflow-x: auto;
        white-space: nowrap;
    }
    table th {
        padding-top: 2px;
        padding-bottom: 2px;
        text-align: center;
        background-color: var(--main);
        color: white;
        position: sticky;
        top: 0;
        font-size: 20px;
    }

    @media only screen and (max-width: 600px){
        table th {
            position: unset;
        }
        table td {
            max-width: 200px;
            overflow-x: auto;
            white-space: nowrap;
        }
    }
    .radio-archive-pagination{
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    ::v-deep(.el-pagination.is-background .btn-next i){
        transform: rotate(180deg);
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    ::v-deep(.el-pagination.is-background .btn-prev i){
        transform: rotate(180deg);
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    ::v-deep(.el-pagination.is-background .el-pager li:not(.disabled).active){
        background: var(--success);
        color: #fff;
    }

    .icon{
        cursor: pointer;
        font-size: 25px;
        margin: 0 5px;
    }
    .delete-icon {
        color: var(--danger);
    }
    .edit-icon {
        color: var(--warning);
    }
    .copy-icon{
        color: var(--blue);
    }
</style>