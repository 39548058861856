<template>
  <div class="radio-manage">
      <div class="radio-manage-header">
          <h2>ניהול רדיו</h2>
      </div>
      <div class="radio-manage-options">
           <el-button @click="state='CreateNewSong'; is_dispatch=false" type="success">הוספת שיר</el-button>
           <el-button @click="handle_dispatch_song" style="margin-right:5px;" type="danger">שיגור שיר קידום</el-button>
           <el-button @click="state='RadioArchive'; is_dispatch=false" style="margin-right:5px;" type="primary">ניהול ליסט</el-button>
           <el-button @click="state='PromotionSongManage'; is_dispatch=false" style="margin-right:5px;" type="info">ניהול שיר קידום</el-button>
      </div>
      <div class="radio-manage-content">
          <component :is="state" :is_dispatch="is_dispatch" />
      </div>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import CreateNewSong from './CreateNewSong.vue'
import RadioArchive from './RadioArchive.vue'
import PromotionSongManage from './PromotionSongManage.vue'

export default {
    components:{CreateNewSong,RadioArchive,PromotionSongManage},
    setup(){
        const state = ref('RadioArchive')
        const is_dispatch = ref(false)

        const handle_dispatch_song = () => {
            is_dispatch.value = true
            state.value = 'CreateNewSong'
        }
        return{
            handle_dispatch_song,
            state,
            is_dispatch,
        }
    }
}
</script>

<style scoped>
    .radio-manage{
        position: relative;
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;
        height: 100%;
        padding-top: 5px;
        
    }
    .radio-manage-header{
        width: 100%;
        height: 50px;
        background: var(--secondary);
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff
    }
    .radio-manage-options{
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
    }
    .radio-manage-content{
        position: relative;
        width: 100%;
        height: calc(100% - 100px);
    }
    
    ::v-deep(.el-button){
        margin: 0;
    }
    
</style>